<template>
    <div class="selected-list">
        <div v-for="profile in profiles" :key="profile.id" class="selected-list__item">
            <img
                :src="profile.avatar"
                alt="avatar"
                class="avatar-md"
                :style="{ width: '38px', height: '38px' }"
            />
            <p>{{ profile.name }}</p>
            <span class="button-remove" @click="$emit('remove', profile)" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectedProfileList',

    props: {
        profiles: {
            type: Array,
            required: true
        }
    },
    emits: ['remove']
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';
.button-remove {
    background-image: url('../../assets/cross.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-color: $beige;
    background-size: 10px 10px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}

.selected-list {
    padding: 1rem;
    background: white;
    overflow: auto;
    width: 100%;
    display: grid;
    column-gap: 1rem;
    justify-content: start;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(min-content, 1fr));

    &__item {
        place-items: center;
        display: grid;
        justify-items: start;
        column-gap: 0.5rem;
        grid-template-columns: auto 1fr auto;
        width: auto;
        white-space: nowrap;
    }
}
</style>
