<template>
    <div class="eventrequest" :class="{ 'eventrequest--spacing-bottom': $attrs.value.length }">
        <LoadingEllipsis v-if="loading">
            <p :style="{ padding: '1rem', paddingRight: '0', display: 'inline-block' }">
                Loading
            </p>
        </LoadingEllipsis>
        <RequestItem
            v-for="profile in profileList"
            :key="profile.id"
            :profile="profile"
            :is-selected="isSelected(profile)"
            @add="selectProfile"
            @remove="unselectProfile"
        />
        <div v-if="$attrs.value.length" class="fixed-footer">
            <p class="fixed-footer__header font-weight-medium">Selected venues</p>
            <SelectedProfileList :profiles="$attrs.value" @remove="unselectProfile" />
            <Button
                label="Continue"
                :style="bigButtonStyles"
                square
                primary
                @click.stop="$emit('step')"
            />
        </div>
    </div>
</template>

<script>
import SelectedProfileList from './SelectedProfileList'
import RequestItem from './RequestItem'

export default {
    name: 'ProfileSelect',
    components: {
        SelectedProfileList,
        RequestItem
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        profileList: {
            type: Array,
            required: true
        }
    },
    emits: ['step', 'update'],
    data() {
        return {
            bigButtonStyles: {
                width: '100%',
                height: '90px',
                borderRadius: '0'
            }
        }
    },
    methods: {
        isSelected(item) {
            return this.$attrs.value.some((i) => i === item)
        },
        selectProfile(profile) {
            this.$emit('update', [...this.$attrs.value, profile])
        },
        unselectProfile(profile) {
            this.$emit(
                'update',
                this.$attrs.value.filter((p) => p.id !== profile.id)
            )
        }
    }
}
</script>

<style lang="scss" scoped>
.eventrequest {
    overflow: auto;
    width: 100%;
    // display: grid;
    row-gap: 1rem;
    padding: 1rem;

    .request-box + .request-box {
        margin-top: 0.5rem;
    }
    &--spacing-bottom {
        padding-bottom: 200px;
    }
}
.fixed-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    background: white;
    &__header {
        padding: 0.5rem 1rem;
    }
}
</style>
