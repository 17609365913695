<template>
    <div :id="profile.id" class="request-box">
        <div class="request-box__row-top">
            <div class="request-box__avatar">
                <img :src="profile.avatar" class="avatar-lg" />
            </div>
            <div class="request-box__body">
                <h3 class="request-box__name">{{ profile.name }}</h3>
                <small class="request-box__categories">
                    {{ toCategoryString }}
                </small>
            </div>
        </div>
        <div class="request-box__row-bottom">
            <div class="request-box__details">
                <div class="request-box__rating">
                    <img v-for="i in 5" :key="i" src="@/assets/icons/star_rating_gold_icon.svg" />
                </div>
                <div class="request-box__member-status">
                    <img src="@/assets/icons/trophy_icon.svg" class="icon-xsm" />
                    <small>Gold member</small>
                </div>
                <div class="request-box__distance">
                    <img src="@/assets/icons/geo_tag_icon.svg" alt="geo-tag" class="icon-xsm" />
                    <small>{{ toDistanceString(profile.calcDistance) }}</small>
                </div>
            </div>
            <div class="request-box__request-button">
                <Button
                    :label="
                        `Request this ${
                            profile.__typename === 'ArtistSuggestion' ? 'artist' : 'venue'
                        }`
                    "
                    square
                    fluid
                    :primary="isSelected"
                    size="small"
                    @click="handleClick"
                ></Button>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/Button'
export default {
    name: 'RequestItem',
    components: {
        Button
    },
    props: {
        profile: {
            type: Object,
            required: true
        },
        isSelected: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input', 'remove', 'add'],
    computed: {
        toCategoryVm() {
            if (this.profile.categories === null) {
                return []
            }
            return (
                Object.entries(this.profile.categories)
                    .map(([category, types]) => {
                        return (
                            category.toUpperCase() +
                            ':' +
                            Object.entries(types)
                                .map(([type, genres]) => {
                                    return type + ' / ' + genres.join(', ')
                                })
                                .join(' ')
                        )
                    })
                    .join('') || ''
            )
        },
        toCategoryString() {
            if (this.profile.__typename === 'ArtistSuggestion') {
                return this.toArtistCategoryString
            } else {
                return this.toVenueCategoryString
            }
        },
        toVenueCategoryString() {
            return Object.entries(this.profile.categories)
                .map((entry) => entry[1])
                .flatMap((x) => x)
                .join(' / ')
        },
        toArtistCategoryString() {
            return Object.entries(this.profile.categories)
                .map((entry) => entry[1])
                .map(Object.values)
                .flatMap((x) => x)
                .flatMap((x) => x)
                .join(' / ')
        }
    },
    methods: {
        handleClick() {
            if (this.isSelected) {
                this.$emit('remove', this.profile)
            } else {
                this.$emit('add', this.profile)
            }
        },
        toDistanceString(distance) {
            if (distance < 1000) {
                return `${Math.round(distance)} meters away`
            } else {
                return `${Math.round((distance / 1000) * 10) / 10} km away`
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.request-box {
    display: grid;
    background-color: $white;
    padding: 0.5rem;
    border-radius: 5px;
    row-gap: 0.5rem;

    &__row-top + &__row-bottom {
        padding-top: 0.5rem;
        border-top: 1px solid $light;
    }

    &__row-top {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 0.5rem;
    }

    &__body {
        display: grid;
        justify-items: start;
        align-content: center;
        row-gap: 0.5rem;
    }

    &__categories {
        overflow: hidden;
        text-overflow: ellipsis;
        // white-space: nowrap;
    }

    &__name {
        font-weight: 500;
    }

    &__row-bottom {
        display: grid;
        row-gap: 0.5rem;
    }

    &__details {
        display: grid;
        align-items: center;
        grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
    }

    &__rating {
        justify-self: start;
        display: flex;
        & > *:not(:first-child) {
            margin-left: 0.2rem;
        }
    }

    &__member-status {
        justify-self: end;
        align-items: center;
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-content: end;
        column-gap: 0.25rem;
    }

    &__distance {
        justify-self: end;
        align-items: center;
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-content: end;
        column-gap: 0.25rem;
    }

    &__request-button {
        justify-self: stretch;
    }
}
</style>
