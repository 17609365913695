<template>
    <div class="filter-bar" @click="$emit('click')">
        <p>{{ text }}</p>
        <div class="filter-bar__button">
            <p>Filter</p>
            <img alt="filter" class="icon-xsm" src="@/assets/icons/settings_icon.svg" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'FilterBar',
    props: {
        text: {
            type: String,
            default: ''
        }
    },
    emits: ['click']
}
</script>

<style lang="scss" scoped>
.filter-bar {
    display: grid;
    grid-template-columns: 1fr auto;
    background-color: white;
    text-align: left;
    padding: 1rem;
    &__button {
        display: grid;
        grid-template-columns: 1fr auto;
        place-items: center;
        column-gap: 0.5rem;
    }
}
</style>
